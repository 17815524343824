<template>
  <div>
    <RenderBars :bars="docs" v-if="docs" />

    <div id="ab_bottom_observer" ref="abBottomObserver"></div>

    <div id="bottom_bar_ref" class="ab-bottom-fixed" ref="bottomBarRef">
      <RenderBars :bars="stickyDocs" v-if="stickyDocs" />
    </div>

  </div>
</template>

<script>
import axios from "axios";

import RenderBars from "./components/render-bars.vue";

export default {
  name: "App",
  components: {
    RenderBars,
  },
  data: function () {
    return {
      docs: null,
      abBottomObserver: null,
      bottomBarRef: null,
      stickyDocs: null,
    };
  },
  async mounted() {
    await this.getTopBars();

    this.abBottomObserver = this.$refs.abBottomObserver;
    this.bottomBarRef = this.$refs.bottomBarRef;

    if(this.stickyDocs && this.stickyDocs.length){
      window.addEventListener('scroll', this.debounce(this.handleScroll, 16.67));
    }

  },
  methods: {
    getTopBars: async function () {
      let url = window.location.href.split("/")[2];
      const response = await axios.get(
        `https://${url}/ext/announcement-bar/application/api/v1/bars?page_no=1&page_size=10&active=true&position=Bottom`
      );

      this.docs = response.data.data.docs && response.data.data.docs.length ? response.data.data.docs.filter(x => !x.configs.displayConfig.stickyBar) : null;
      this.stickyDocs = response?.data?.data?.docs && response.data.data.docs.length ? response.data.data.docs.filter(x => x.configs.displayConfig.stickyBar) : null;
    },

    debounce(func, wait) {
      let timeout;
      return function executedFunction(...args) {
        const later = () => {
          clearTimeout(timeout);
          func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
      };
    },

    handleScroll() {
      const position = this.abBottomObserver.getBoundingClientRect();

      if (position.top >= 0 && position.bottom <= window.innerHeight) {
        if (this.bottomBarRef.classList.contains('ab-bottom-fixed')) {
          this.bottomBarRef.classList.remove('ab-bottom-fixed');
          this.isAbBottomFixed = false;
        }
      } else {
        if (!this.bottomBarRef.classList.contains('ab-bottom-fixed')) {
          this.bottomBarRef.classList.add('ab-bottom-fixed');
          this.isAbBottomFixed = true;
        }
      }
      window.requestAnimationFrame(() => this.handleScroll());
    },
  },
}

</script>

<style lang="scss">
@import "./styles/storefront-variables";
@import "./styles/announcementbar.scss";
/// all classes should start with `.ab-`

.ab-bottom-fixed {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  z-index: 3;
}

</style>
