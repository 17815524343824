
/**
 * @typedef {(import('../../../../models/config-groups/CTA').Cta)} Cta
 * @param {('button' | 'link')} ctaType
 * @param {Cta | {} | undefined} callToAction config object containing CTA data
 * @returns {boolean}
 */
export function showCTA(ctaType, callToAction) {
    // @ts-check
    if (!callToAction || !Object.keys(callToAction).length) {
        return false;
    }
    return (callToAction.type.toLowerCase() === ctaType && callToAction.show);
}
