<template>
  <div :id="[generateDynamicID]" :class="[
    { 'ab-sticky-top': isStickyTop, 'ab-sticky-bottom': isStickyBottom },
  ]" ref="countdownTimerRef">
    <div class="ab-container ab-countdown-timer-container">
      <!-- Hide and Show bar based on the delay and timer -->
      <div v-if="!isHidden && showBar" class="ab-flex-container ab-bg-Repeat-Cover" :class="[addPatternClass]"
        :style="{ background: barBackground }">
        <div class="ab-flex-item ab-content">
          <div class="ab-row-item ab-countdown-timer"
            v-if="value.configs.countdownTimer.style.toLowerCase() == 'basic' && showCountdown">
            <div id="countdown">
              <ul class="ab-countdown-content"
                :style="{
                  '--ab-timer-bg': value.configs.countdownTimer.bg,
                  '--ab-timer-font-color': value.configs.countdownTimer.fontColor,
                }"
              >
                <li class="ab-countdown-item">
                  <span id="days">{{ days | doubleDigit }}</span>DD
                </li>
                <li class="ab-countdown-item">
                  <span id="hours">{{ hours | doubleDigit }}</span>HH
                </li>
                <li class="ab-countdown-item">
                  <span id="minutes">{{ minutes | doubleDigit }}</span>MM
                </li>
                <li class="ab-countdown-item">
                  <span id="seconds">{{ seconds | doubleDigit }}</span>SS
                </li>
              </ul>
            </div>
          </div>

          <div class="ab-row-item ab-countdown-content-container ab-countdown-text">
            <!-- Inner HTML content to be rendered -->
            <div v-html="value.configs.content.text" class="ab-row-item"></div>
          </div>
          <div class="ab-row-item ab-countdown-cta"
            v-if="value.configs.callToAction && value.configs.callToAction.show">
            <!-- When call acton type is button and related effects and styles -->
            <button v-if="isButton"
              :class="{
                'ab-preview-button': isButton,
                'ab-wiggle-effect': value.configs.callToAction.isWiggle,
              }"
              :style="{
                background: value.configs.callToAction.bg,
                color: value.configs.callToAction.fontColor,
              }"
              @click="callAction(value.configs.callToAction)" class="ab-row-item"
            >
              {{ value.configs.callToAction.text }}
            </button>

            <!-- When call action type is link and its related styles -->
            <div v-if="isLink" class="ab-link-action ab-row-item">
              <a :style="{
                color: value.configs.callToAction.fontColor,
                'border-bottom': `2px solid ${value.configs.callToAction.fontColor}`,
              }" @click="callAction(value.configs.callToAction)">{{ value.configs.callToAction.text }}</a>
            </div>
          </div>
        </div>
        <div class="ab-flex-item ab-close-icon" v-if="value.configs.displayConfig.showCloseIcon" @click="hideBar()">
          <svg width="8" height="8" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path :style="{ fill: value.configs.displayConfig.iconColor }"
              d="M8.32311 0.874286L7.48484 0L4.16152 3.46614L0.838201 0L-6.10352e-05 0.874286L3.32326 4.34043L-6.10352e-05 7.80657L0.838201 8.68085L4.16152 5.21471L7.48484 8.68085L8.32311 7.80657L4.99978 4.34043L8.32311 0.874286Z"
              fill="#8e2e2e"></path>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Stuck } from "stuck-js";
import { showCTA } from "../../utils/cta.util";

export default {
  name: "countDownTimerBar",
  components: {},
  props: {
    value: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
  data() {
    return {
      // value: {
      //   configs: {
      //     countdownTimer: {
      //       start: 1681355270000,
      //       end: 1681571270000,
      //       style: "Basic",
      //       fontColor: "#5644B3",
      //       bg: "#FFFFFF"
      //     },
      //     displayConfig: {
      //       barBackground: "Solid",
      //       backgroundColor: "#7B61FF",
      //       barPosition: "Top",
      //       showCloseIcon: true,
      //       iconColor: "#FFFFFF",
      //       stickyBar: true,
      //       pattern: "Pattern1"
      //     },
      //     content: {
      //       text: "<p>Black Friday - Sale 50% OFF</p>"
      //     },
      //     callToAction: {
      //       type: "Button",
      //       category: "link",
      //       show: true,
      //       text: "Shop Now",
      //       bg: "#F8F8F8",
      //       fontColor: "#4F4F4F",
      //       link: "",
      //       isWiggle: true
      //     },
      //     delayTimer: {
      //       show: true,
      //       showBar: 0,
      //       timer: 1
      //     },
      //     targetPageAndPlatform: {
      //       page: [
      //         {
      //           display: "About Us",
      //           page_type: "about-us",
      //           action: {
      //             page: {
      //               type: "about-us",
      //               url: "about-us"
      //             },
      //             type: "system"
      //           }
      //         },
      //         {
      //           display: "Add Product Review",
      //           page_type: "add-product-review",
      //           action: {
      //             page: {
      //               type: "add-product-review",
      //               url: "product/:slug/add-review"
      //             },
      //             type: "system"
      //           }
      //         },
      //         {
      //           display: "Blog",
      //           page_type: "blog",
      //           action: {
      //             page: {
      //               type: "blog",
      //               url: "blog"
      //             },
      //             type: "system"
      //           }
      //         },
      //         {
      //           display: "Brand Listing",
      //           page_type: "brand-listing",
      //           action: {
      //             page: {
      //               type: "brand-listing",
      //               url: "brand"
      //             },
      //             type: "system"
      //           }
      //         },
      //         {
      //           display: "Brands",
      //           page_type: "brands",
      //           action: {
      //             page: {
      //               type: "brands",
      //               url: "brands"
      //             },
      //             type: "system"
      //           }
      //         },
      //         {
      //           display: "Testpage",
      //           page_type: "c:::testpage",
      //           action: {
      //             page: {
      //               type: "c:::testpage",
      //               url: "c/testpage"
      //             },
      //             type: "custom"
      //           }
      //         },
      //         {
      //           display: "Cart Delivery",
      //           page_type: "cart-delivery",
      //           action: {
      //             page: {
      //               type: "cart-delivery",
      //               url: "cart/delivery"
      //             },
      //             type: "system"
      //           }
      //         },
      //         {
      //           display: "Cart Landing",
      //           page_type: "cart-landing",
      //           action: {
      //             page: {
      //               type: "cart-landing",
      //               url: "cart/bag"
      //             },
      //             type: "system"
      //           }
      //         },
      //         {
      //           display: "Payment Info",
      //           page_type: "cart-payment",
      //           action: {
      //             page: {
      //               type: "cart-payment",
      //               url: "cart/payment-info"
      //             },
      //             type: "system"
      //           }
      //         },
      //         {
      //           display: "Cart Review",
      //           page_type: "cart-review",
      //           action: {
      //             page: {
      //               type: "cart-review",
      //               url: "cart/order-review"
      //             },
      //             type: "system"
      //           }
      //         },
      //         {
      //           display: "Categories",
      //           page_type: "categories",
      //           action: {
      //             page: {
      //               type: "categories",
      //               url: "categories"
      //             },
      //             type: "system"
      //           }
      //         },
      //         {
      //           display: "Category Listing",
      //           page_type: "category-listing",
      //           action: {
      //             page: {
      //               type: "category-listing",
      //               url: "category"
      //             },
      //             type: "system"
      //           }
      //         },
      //         {
      //           display: "Collection Listing",
      //           page_type: "collection-listing",
      //           action: {
      //             page: {
      //               type: "collection-listing",
      //               url: "collection"
      //             },
      //             type: "system"
      //           }
      //         },
      //         {
      //           display: "Collections",
      //           page_type: "collections",
      //           action: {
      //             page: {
      //               type: "collections",
      //               url: "collections"
      //             },
      //             type: "system"
      //           }
      //         },
      //         {
      //           display: "Compare Products",
      //           page_type: "compare-products",
      //           action: {
      //             page: {
      //               type: "compare-products",
      //               url: "compare"
      //             },
      //             type: "system"
      //           }
      //         },
      //         {
      //           display: "Faq",
      //           page_type: "faq",
      //           action: {
      //             page: {
      //               type: "faq",
      //               url: "faq"
      //             },
      //             type: "system"
      //           }
      //         },
      //         {
      //           display: "Home",
      //           page_type: "home",
      //           action: {
      //             page: {
      //               type: "home",
      //               url: ""
      //             },
      //             type: "system"
      //           }
      //         },
      //         {
      //           display: "Login",
      //           page_type: "login",
      //           action: {
      //             page: {
      //               type: "login",
      //               url: "auth/login"
      //             },
      //             type: "system"
      //           }
      //         },
      //         {
      //           display: "Not Found Page",
      //           page_type: "not-found-page",
      //           action: {
      //             page: {
      //               type: "not-found-page",
      //               url: "*"
      //             },
      //             type: "system"
      //           }
      //         },
      //         {
      //           display: "Order Status",
      //           page_type: "order-status",
      //           action: {
      //             page: {
      //               type: "order-status",
      //               url: "cart/order-status/"
      //             },
      //             type: "system"
      //           }
      //         },
      //         {
      //           display: "Order Tracking",
      //           page_type: "order-tracking",
      //           action: {
      //             page: {
      //               type: "order-tracking",
      //               url: "order-tracking"
      //             },
      //             type: "system"
      //           }
      //         },
      //         {
      //           display: "Order Tracking Details",
      //           page_type: "order-tracking-details",
      //           action: {
      //             page: {
      //               type: "order-tracking-details",
      //               url: "order-tracking/:orderId"
      //             },
      //             type: "system"
      //           }
      //         },
      //         {
      //           display: "Orders List",
      //           page_type: "orders-list",
      //           action: {
      //             page: {
      //               type: "orders-list",
      //               url: "profile/orders"
      //             },
      //             type: "system"
      //           }
      //         },
      //         {
      //           display: "Product Description",
      //           page_type: "product-description",
      //           action: {
      //             page: {
      //               type: "product-description",
      //               url: "product"
      //             },
      //             type: "system"
      //           }
      //         },
      //         {
      //           display: "Product Listing",
      //           page_type: "product-listing",
      //           action: {
      //             page: {
      //               type: "product-listing",
      //               url: "products"
      //             },
      //             type: "system"
      //           }
      //         },
      //         {
      //           display: "Product Reviews",
      //           page_type: "product-reviews",
      //           action: {
      //             page: {
      //               type: "product-reviews",
      //               url: "product/:slug/reviews"
      //             },
      //             type: "system"
      //           }
      //         },
      //         {
      //           display: "Profile Address",
      //           page_type: "profile-address",
      //           action: {
      //             page: {
      //               type: "profile-address",
      //               url: "profile/address"
      //             },
      //             type: "system"
      //           }
      //         },
      //         {
      //           display: "Profile Details",
      //           page_type: "profile-details",
      //           action: {
      //             page: {
      //               type: "profile-details",
      //               url: "profile/details"
      //             },
      //             type: "system"
      //           }
      //         },
      //         {
      //           display: "Register",
      //           page_type: "register",
      //           action: {
      //             page: {
      //               type: "register",
      //               url: "auth/register"
      //             },
      //             type: "system"
      //           }
      //         },
      //         {
      //           display: "Shipment Details",
      //           page_type: "shipment-details",
      //           action: {
      //             page: {
      //               type: "shipment-details",
      //               url: "orders/shipment/:shipmentId"
      //             },
      //             type: "system"
      //           }
      //         },
      //         {
      //           display: "Single Page Checkout",
      //           page_type: "single-page-checkout",
      //           action: {
      //             page: {
      //               type: "single-page-checkout",
      //               url: "cart/checkout"
      //             },
      //             type: "system"
      //           }
      //         },
      //         {
      //           display: "Wishlist",
      //           page_type: "wishlist",
      //           action: {
      //             page: {
      //               type: "wishlist",
      //               url: "wishlist"
      //             },
      //             type: "system"
      //           }
      //         }
      //       ],
      //       platform: [
      //         "web",
      //         "android",
      //         "ios"
      //       ]
      //     }
      //   }
      // },
      Position: {
        top: "top",
        bottom: "bottom",
      },
      barData: null,
      displayBar: true,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      distance: 0,
      showCountdown: false,
      isHidden: false,
      showBar: true,
      countDownTimer: {
        timeRef: null,
        timeToEnd: 0,
      },
      timeIntervalRef: {
        showBarOnDelayRef: null,
        showBarAfterTimerRef: null,
      },
    };
  },
  computed: {
    /**
     * Computed method to check if type is button and supposed to show
     */
    isButton() {
      return showCTA("button", this.value.configs.callToAction);
    },
    /**
     * Computed method to check if type is link and supposed to show
     */
    isLink() {
      return showCTA("link", this.value.configs.callToAction);
    },
    /**
     * Computed method to check if Bar background is solid and add respective background color
     */
    barBackground() {
      return this.value.configs.displayConfig.barBackground.toLowerCase() ==
        "solid"
        ? this.value.configs.displayConfig.backgroundColor
        : "";
    },
    /**
     * Computed method to check if Bar background is pattern and generate respective pattern names for binding classes
     */
    addPatternClass() {
      let patternName;
      if (
        this.value.configs.displayConfig.barBackground.toLowerCase() ==
        "pattern"
      ) {
        let patterntype =
          this.value.configs.displayConfig?.pattern.toLowerCase();
        let lastChar = patterntype[patterntype.length - 1];
        patternName = `pattern_${lastChar}`;
      }
      return patternName ? patternName : "no-pattern";
    },
    /**
     * Computed method to check for bar positioning
     */
    topPositioned() {
      return (
        this.value.configs.displayConfig.barPosition.toLowerCase() == "top"
      );
    },
    /**
     * Computed method to check for bar positioning
     */
    bottomPositioned() {
      return (
        this.value.configs.displayConfig.barPosition.toLowerCase() == "bottom"
      );
    },
    isStickyTop() {
      return this.value.configs.displayConfig.stickyBar &&
        this.value.configs.displayConfig.barPosition.toLowerCase() == "top"
        ? true
        : false;
    },
    isStickyBottom() {
      return this.value.configs.displayConfig.stickyBar &&
        this.value.configs.displayConfig.barPosition.toLowerCase() == "bottom"
        ? true
        : false;
    },

    generateDynamicID() {
      return `countdowntimer-bar-ref-${this.index}`;
    },

    // getTheClosestWrapper(){
    //   return this.$refs.countdownTimerRef.closest('#ext-announcement-bar-top-view');
    // },
    /**
     * Computed method to check on link performed while clicked on link or button based on the the type
     */
    LinkActionPerfomed() {
      let content = "";
      switch (this.value.configs.callToAction.category) {
        case "link":
          content = this.value.configs.callToAction.link;
          break;
        case "email":
          content = `mailto:${this.value.configs.callToAction.link}`;
          break;
        case "mobile":
          content = `tel:${this.value.configs.callToAction.link}`;
          break;
      }
      return content;
    },
  },

  mounted() {
    this.showBarOnDelay();
    this.showCountdownOnBar();

    const second = 1000,
      minute = second * 60,
      hour = minute * 60,
      day = hour * 24;
    this.countDownTimer.timeToEnd = 0;

    this.countDownTimer.timeRef = setInterval(() => {
      this.countDownTimer.timeToEnd =
        this.value.configs.countdownTimer.end - Date.now();
      this.days = Math.floor(this.countDownTimer.timeToEnd / day);
      this.hours = Math.floor((this.countDownTimer.timeToEnd % day) / hour);
      this.minutes = Math.floor(
        (this.countDownTimer.timeToEnd % hour) / minute
      );
      this.seconds = Math.floor(
        (this.countDownTimer.timeToEnd % minute) / second
      );

      this.showCountdownOnBar();
    }, 0);

    if (this.isStickyTop) {
      new Stuck(
        [
          {
            selector: `#countdowntimer-bar-ref-${this.index}`,
            wrapper: "#app",
          },
        ],
        { marginTop: 0 }
      );

    }
  },
  destroyed() {
    if (this.countDownTimer.timeToEnd < 0 && this.countDownTimer.timeRef) {
      clearInterval(this.countDownTimer.timeRef);
    }
    clearTimeout(this.timeIntervalRef.showBarOnDelayRef);
    clearTimeout(this.timeIntervalRef.showBarAfterTimerRef);
  },
  methods: {
    /**
     * Method to determine what should be the call of action based on different types
     * @param {*} cta : Call to Action types and actions
     */
    callAction: (cta) => {
      if (!cta) {
        return;
      }
      switch (cta.category) {
        case "email": {
          if (!cta.email) {
            break;
          }
          window.location.href = `mailto:${cta.email}`;
          break;
        }
        case "phone": {
          if (!cta.phoneNumber) {
            break;
          }
          window.open(`tel://${cta.phoneNumber}`);
          break;
        }
        case "link": {
          if (!cta.link) {
            break;
          }
          window.open(cta.link, "_blank");
          break;
        }
        default: {
          console.error("CTA category not supported");
          break;
        }
      }
    },

    /**
     * Method triggered once close button is clicked
     */
    hideBar() {
      this.isHidden = true;
      this.showBarAfterTimer();
    },

    /**
     * Method to show bar after delay
     */
    showBarOnDelay() {
      if (this.value.configs.delayTimer.show === true) {
        if (this.value.configs.delayTimer.showBar > 0) {
          this.showBar = false;
          this.timeIntervalRef.showBarOnDelayRef = setTimeout(() => {
            this.showBar = true;
          }, this.value.configs.delayTimer.showBar);
        } else {
          this.showBar = true;
        }
      }
    },

    /**
     * Method to show bar after it has been closed after certain time interval
     */
    showBarAfterTimer() {
      if (this.value.configs.delayTimer.show === true) {
        this.timeIntervalRef.showBarAfterTimerRef = setTimeout(() => {
          this.isHidden = false;
        }, Number(this.value.configs.delayTimer.timer * 1000));
      }
    },

    /**
     * Method to show countdown when its between the start and end time
     */
    showCountdownOnBar() {
      const startDate = new Date(this.value.configs.countdownTimer.start);
      const endDate = new Date(this.value.configs.countdownTimer.end);
      const current = Date.now();

      this.showCountdown = current > startDate && current < endDate;
    },
  },
  filters: {
    doubleDigit: function (value) {
      // @ts-check
      if (typeof value !== 'number') {
        return '00';
      }
      let valueString = value.toString();
      if (valueString.length < 2) {
        valueString = `0${valueString}`;
      }
      return valueString;
    }
  },
};
</script>
<style scoped lang="scss">

</style>
