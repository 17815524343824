var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"announcementBarRef",class:[
    { 'ab-sticky-top': _vm.isStickyTop, 'ab-sticky-bottom': _vm.isStickyBottom },
  ],attrs:{"id":[_vm.generateDynamicID]}},[_c('div',{staticClass:"ab-container"},[(!_vm.isHidden && _vm.showBar)?_c('div',{staticClass:"ab-flex-container ab-bg-Repeat-Cover",class:[_vm.addPatternClass],style:({ background: _vm.barBackground })},[(_vm.marquee)?_c('div',{staticClass:"ab-flex-item ab-content"},[_c('marquee',[_c('div',{staticClass:"ab-marquee-container"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.value.configs.content.text)}}),(_vm.isButton)?_c('button',{class:{
                'ab-preview-button': _vm.isButton,
                'ab-wiggle-effect': _vm.value.configs.callToAction.isWiggle,
              },style:({
                background: _vm.value.configs.callToAction.bg,
                color: _vm.value.configs.callToAction.fontColor,
              }),on:{"click":function($event){return _vm.callAction(_vm.value.configs.callToAction)}}},[_vm._v(" "+_vm._s(_vm.value.configs.callToAction.text)+" ")]):_vm._e(),(_vm.isLink)?_c('div',{staticClass:"ab-link-action"},[_c('a',{style:({
                  color: _vm.value.configs.callToAction.fontColor,
                  'border-bottom': `2px solid ${_vm.value.configs.callToAction.fontColor}`,
                }),on:{"click":function($event){return _vm.callAction(_vm.value.configs.callToAction)}}},[_vm._v(_vm._s(_vm.value.configs.callToAction.text))])]):_vm._e()])])],1):_vm._e(),(!_vm.marquee)?_c('div',{staticClass:"ab-flex-item ab-content"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.value.configs.content.text)}}),(_vm.isButton)?_c('button',{class:{
            'ab-preview-button': _vm.isButton,
            'ab-wiggle-effect': _vm.value.configs.callToAction.isWiggle,
          },style:({
            background: _vm.value.configs.callToAction.bg,
            color: _vm.value.configs.callToAction.fontColor,
          }),on:{"click":function($event){return _vm.callAction(_vm.value.configs.callToAction)}}},[_vm._v(" "+_vm._s(_vm.value.configs.callToAction.text)+" ")]):_vm._e(),(_vm.isLink)?_c('div',{staticClass:"ab-link-action"},[_c('a',{style:({
              color: _vm.value.configs.callToAction.fontColor,
              'border-bottom': `2px solid ${_vm.value.configs.callToAction.fontColor}`,
            }),on:{"click":function($event){return _vm.callAction(_vm.value.configs.callToAction)}}},[_vm._v(_vm._s(_vm.value.configs.callToAction.text))])]):_vm._e()]):_vm._e(),(_vm.value.configs.displayConfig.showCloseIcon)?_c('div',{staticClass:"ab-flex-item ab-close-icon",on:{"click":function($event){return _vm.hideBar()}}},[_c('svg',{attrs:{"width":"8","height":"8","viewBox":"0 0 9 9","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{style:({ fill: _vm.value.configs.displayConfig.iconColor }),attrs:{"d":"M8.32311 0.874286L7.48484 0L4.16152 3.46614L0.838201 0L-6.10352e-05 0.874286L3.32326 4.34043L-6.10352e-05 7.80657L0.838201 8.68085L4.16152 5.21471L7.48484 8.68085L8.32311 7.80657L4.99978 4.34043L8.32311 0.874286Z","fill":"#8e2e2e"}})])]):_vm._e()]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }