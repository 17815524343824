import axios from "axios";

import { getBaseUrl } from "./url.util";

/**
 * Method to handle cart events
 * @param {*} eventName : Name of the event
 * @param {*} eventData : Callback of event data
 * @returns callback
 */

const handleCartEvent = (func, eventName) => {
    if (!window.FPI?.event) {
        return;
    }
    window.FPI.event.on(eventName, function (eventData) {
        switch (eventName) {
            case "cart.newProduct":
                func(handleNewProductAddedToCart(eventData));
                break;
            case "cart.view":
                func(handleCartView(eventData));
                break;
            case "cart.remove":
                func(handleRemovedProductFromCart(eventData));
                break;
            case "cart.update":
                func(handleUpdatedProductInCart(eventData));
                break;
            default:{
                console.error("Unknown event:", eventName);
                const existingProducts = localStorage.getItem("productsCount");
                func(existingProducts ? existingProducts.totalPrice : null);
                break;
            }
        }
    });
};


/**
 * Method to handle New Product added to the cart and update the total price in localstorage
 * @param {*} eventData : Data received on callback when new product is been added to the cart
 */
const handleNewProductAddedToCart = (eventData) => {
    const existingProducts = localStorage.getItem("productsCount");
    const updatedPrice = existingProducts !== null ? Number(existingProducts.totalPrice + calculateProductTotal(eventData)) : calculateProductTotal(eventData);
    setLocalStorage("productsCount", { totalPrice: updatedPrice });
    return updatedPrice;
};

/**
 * Method to handle Cart view and update the total price in localstorage
 * @param {*} eventData : Data received on callback when cart is viewed
 */
const handleCartView = (eventData) => {
    const existingProducts = localStorage.getItem("productsCount");
    const totalVal = Number(calculateProductTotal(eventData));
    if (existingProducts === null || existingProducts?.totalPrice < totalVal) {
        setLocalStorage("productsCount", { totalPrice: totalVal });
    }
    return totalVal;
};

/**
 * Method to handle removed products from the cart and update the total price in localstorage
 * @param {*} eventData : Data received on callback when product item is removed from the cart
 */
const handleRemovedProductFromCart = (eventData) => {
    const existingProducts = localStorage.getItem("productsCount");
    const totalVal = Number(calculateProductTotal(eventData));
    const updatedPrice = existingProducts !== null ? Number(existingProducts.totalPrice - totalVal) : existingProducts.totalPrice;
    setLocalStorage("productsCount", { totalPrice: updatedPrice });
    return updatedPrice;
};

/**
 * Method to handle updated products from the cart and update the total price in localstorage
 * @param {*} eventData : Data received on callback when product item is updated from the cart
 */
const handleUpdatedProductInCart = (eventData) => {
    let updatedPrice = 0;
    const existingProducts = localStorage.getItem("productsCount");
    const patchVal = Number(calculateProductForUpdate(eventData));
    const currentCartValue =  existingProducts !== null ? existingProducts.totalPrice : 0;
    if (eventData.operation == "increment_quantity") {
        updatedPrice = Number(currentCartValue + patchVal);
    }

    if (eventData.operation == "decrement_quantity" && currentCartValue) {
        updatedPrice = Number(currentCartValue - patchVal);
    } else {
        updatedPrice = 0;
    }

    setLocalStorage("productsCount", { totalPrice: updatedPrice });
    return updatedPrice;
};

/**
 * Method to calculate total product value based on all the products
 * @param {*} data : Event data
 * @returns Value which is the total calculated
 */
const calculateProductTotal = (data) => {
    return data?.products.reduce((acc, product) => {
        return acc + product?.price?.effective;
    }, 0);
};

/**
 * Method to calculate total product value based on all the products for the Update event
 * @param {*} data : Event data
 * @returns Value which is the total calculated from effective price
 */
const calculateProductForUpdate = (data) => {
    return data?.products.reduce((acc, product) => {
        return acc + product?.price_per_unit?.base?.effective;
    }, 0);
};

/**
 * Set Local Storage utility
 * @param {*} key : Local Storage Key
 * @param {*} value : Local Storage value w.r.t key
 */
const setLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
};

const cartValue = () => {
  let _promiseInstance = null;

  return (userId) => {
    if (_promiseInstance) {
      return _promiseInstance;
    }

    _promiseInstance = new Promise((resolve) => {
      async () => {
        if (!userId) {
          resolve({ success: false, message: "USER_ID_NOT_DEFINED" });
          return;
        }
        const url = getBaseUrl();
        let response = null;
        try {
          response = await axios.get(
            `https://${url}/ext/announcement-bar/application/api/v1/cart?user_id=${userId}`
          );

          const [cart] = response.data.data;
          setLocalStorage("productsCount", {
            totalPrice: cart.cart_value,
          });
        } catch (error) {
          /**
           * UI LOGGER SERVICE TO - DO
           */
          console.log(error);
        }

        if (response) {
          resolve({ success: true });
          return;
        }
        resolve({ success: false, message: "API_FAILED" });
      };
    });

    return _promiseInstance;
  };
};

export const getCartValue = cartValue();

export const updateCartValue = (user, cartValue) => {
  const url = getBaseUrl();
    return axios.post(
        `https://${url}/ext/announcement-bar/application/api/v1/cart?user_id=${user._id}`, {
            cart_value: cartValue,
            user: user,
        }
      );
}

export default handleCartEvent;
