<template>
  <div class="ab-swipe-slider-rotating" :style="{ height: sliderHeight + 'px' }">
    <div class="ab-slider">
      <div
        class="ab-slider-wrapper"
        :style="{ transform: 'translateX(' + this.sliderPosition() + '%)' }"
        ref="swipeSlide">
        <div class="ab-slide" v-for="(bar, index) in value.bars" :key="index">
          <component :is="map[bar.type]" v-bind="getProps(bar)"></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarsConfig from "../../configs/bars.config";

import AnnouncementBar from "./../announcement-bar/announcement-bar";
import CountDownTimerBar from "./../countdowntimer-bar/countdowntimer-bar";
import ContactFormBar from "./../contactform-bar/contactform-bar";
import FreeShippingBar from "./../freeshipping-bar/freeshipping-bar";
import SocialShareBar from "./../socialshare-bar/socialshare-bar";
import RotatingBar from "./../rotating-bar/rotating-bar";

export default {
  name: "swipeSlider",
  components: {
    AnnouncementBar,
    CountDownTimerBar,
    ContactFormBar,
    FreeShippingBar,
    SocialShareBar,
    RotatingBar,
  },
  props: {
    value: {
      type: Object,
    },
    isCloseIconVisible: {
      type: Boolean,
    },
  },
  data() {
    return {
      map: BarsConfig,
      currentSlide: 0,
      timeIntervalRef: null,
      sliderHeight: null,
      timeOutRef: null,
    };
  },
  computed: {
    setTransitionSpeed() {
      switch (this.value.configs.displayConfig.transitionSpeed) {
        case "Slow":
          return 10000;
        case "Med":
          return 5000;
        case "Fast":
          return 3000;
        default:
          return null;
      }
    },
  },
  mounted() {
    this.timeOutRef = setTimeout(() => {
      this.sliderHeight = this.$refs.swipeSlide.children[this.currentSlide].children[0].clientHeight;
      this.startAutoplay();
    }, 500);
  },
  methods: {
    startAutoplay() {
      this.timeIntervalRef = setInterval(() => {
        this.currentSlide = (this.currentSlide + 1) % this.value.bars.length;
        this.sliderHeight = this.$refs.swipeSlide.children[this.currentSlide].children[0].clientHeight;
        this.sliderPosition();
      }, this.setTransitionSpeed);
    },
    getProps: function (bar) {
      return {
        value: {
          ...bar,
          configs: {
            ...this.value.configs,
            displayConfig: {
              ...this.value.configs.displayConfig,
              showCloseIcon: false,
              stickyBar: false,
            },
            ...bar.configs,
          },
        },
      };
    },
    sliderPosition() {
      return (-50 * this.currentSlide);
      // return -50 * (this.currentSlide % this.value.bars.length);
    }
  },
  destroyed() {
    if (this.timeIntervalRef) {
      window.clearInterval(this.timeIntervalRef);
    }
    if (this.timeOutRef) {
      clearTimeout(this.timeOutRef);
    }
  },
};
</script>

<style scoped lang="scss">

.ab-swipe-slider-rotating {
  .ab-slider {
    overflow: hidden;
    position: relative;
    width: 100vw;
  }

  .ab-slider-wrapper {
    display: flex;
    width: 200%;
    transition: transform 0.5s ease-in-out;
  }

  .ab-slide {
    flex: 1 0 50%;
  }


}
</style>
