<template>
  <span v-html="getSVG" class="inline-svg"></span>
</template>
<script>
export default {
  name: "GenerateMedia",
  components: {},
  props: {
    mediaLinks: {
      type: Object,
      default() {
        return {
          label: "LinkedIn",
        };
      },
    },
    iconColor: {
      default: "#000",
      type: String,
    },
  },
  computed: {
    getSVG() {
      let linkContext;
      switch (this.mediaLinks.label) {
        case "LinkedIn":
          linkContext = `
            <svg
              width="23"
              height="23"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM3.84054 6.62595H5.65351V12.0732H3.84054V6.62595ZM5.77292 4.9409C5.76115 4.4068 5.37922 4 4.75901 4C4.1388 4 3.73333 4.4068 3.73333 4.9409C3.73333 5.46393 4.12682 5.88245 4.73548 5.88245H4.74706C5.37922 5.88245 5.77292 5.46393 5.77292 4.9409ZM10.1044 6.49804C11.2974 6.49804 12.1918 7.27675 12.1918 8.94991L12.1917 12.0732H10.3788V9.15891C10.3788 8.42691 10.1165 7.92741 9.46017 7.92741C8.9593 7.92741 8.66096 8.26414 8.52993 8.58938C8.48199 8.70594 8.47023 8.86834 8.47023 9.03114V12.0733H6.65703C6.65703 12.0733 6.68093 7.13723 6.65703 6.62609H8.47023V7.39765C8.71085 7.02675 9.14181 6.49804 10.1044 6.49804Z"
                fill=${this.iconColor}
              />
            </svg>
          `;
          break;
        case "Facebook":
          linkContext = `
            <svg
              width="23"
              height="23"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM8.83385 8.3514V12.7038H7.03302V8.35157H6.13333V6.8517H7.03302V5.95119C7.03302 4.7276 7.54105 4 8.9844 4H10.186V5.50004H9.43492C8.87306 5.50004 8.83589 5.70965 8.83589 6.10084L8.83385 6.85153H10.1945L10.0353 8.3514H8.83385Z"
                fill=${this.iconColor}
              />
            </svg>
          `;
          break;
        case "Twitter":
          linkContext = `
            <svg
              width="23"
              height="23"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM7.77719 6.77932L7.7604 6.5025C7.71004 5.78503 8.15211 5.12971 8.85159 4.87549C9.109 4.7851 9.54547 4.7738 9.83086 4.85289C9.94278 4.88679 10.1554 4.99977 10.3065 5.10146L10.5807 5.28789L10.8829 5.19185C11.0508 5.14101 11.2746 5.05627 11.3753 4.99977C11.4704 4.94893 11.5544 4.92068 11.5544 4.93763C11.5544 5.03367 11.3473 5.36133 11.1739 5.54211C10.9388 5.79633 11.006 5.81893 11.4816 5.64945C11.767 5.55341 11.7726 5.55341 11.7167 5.66075C11.6831 5.71724 11.5096 5.91497 11.3249 6.09575C11.0116 6.40646 10.9948 6.44036 10.9948 6.70023C10.9948 7.10133 10.8045 7.93743 10.6143 8.39503C10.2617 9.25373 9.5063 10.1407 8.75086 10.587C7.68766 11.2141 6.27191 11.3722 5.08 11.005C4.68269 10.8807 4 10.5644 4 10.5079C4 10.4909 4.20705 10.4683 4.45886 10.4627C4.98487 10.4514 5.51088 10.3045 5.95854 10.0446L6.26072 9.86386L5.91378 9.74523C5.42134 9.57574 4.97927 9.18594 4.86735 8.81873C4.83378 8.7001 4.84497 8.69445 5.15834 8.69445L5.4829 8.6888L5.2087 8.55886C4.88414 8.39503 4.58756 8.11821 4.44207 7.83575C4.33575 7.63237 4.20145 7.11828 4.24062 7.07873C4.25181 7.06179 4.36933 7.09568 4.50362 7.14088C4.88974 7.28211 4.9401 7.24821 4.71627 7.01094C4.29658 6.58159 4.16787 5.94321 4.36933 5.33873L4.46445 5.06757L4.83378 5.43477C5.58922 6.17484 6.47895 6.61549 7.4974 6.74542L7.77719 6.77932Z"
                fill=${this.iconColor}
              />
            </svg>
          `;
          break;
        case "WhatsApp":
          linkContext = `
            <svg
              width="23"
              height="23"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8ZM13.0207 7.69383C13.0197 10.3161 10.8865 12.4498 8.26369 12.4508H8.26172C7.46557 12.4506 6.68327 12.2508 5.98845 11.8718L3.46667 12.5333L4.14154 10.0683C3.72524 9.34684 3.50619 8.5285 3.50655 7.69008C3.50759 5.06723 5.64161 2.93333 8.26365 2.93333C9.53621 2.93388 10.7306 3.42929 11.6287 4.32847C12.5269 5.22761 13.0212 6.42278 13.0207 7.69383Z"
                fill=${this.iconColor}
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.60867 11.4032L6.10513 11.0106L6.24951 11.0963C6.85657 11.4566 7.55244 11.6472 8.26193 11.6475H8.26354C10.4428 11.6475 12.2165 9.87372 12.2174 7.69356C12.2178 6.63707 11.8069 5.6437 11.0604 4.89633C10.3139 4.14897 9.32117 3.73718 8.26509 3.73682C6.08415 3.73682 4.31044 5.51037 4.30957 7.69036C4.30926 8.43746 4.5183 9.16504 4.91409 9.79455L5.0081 9.94415L4.60867 11.4032ZM10.4973 8.71802C10.5802 8.75809 10.6362 8.78514 10.6601 8.82505C10.6898 8.87461 10.6898 9.11259 10.5908 9.39029C10.4917 9.66793 10.0169 9.92133 9.78847 9.95545C9.5837 9.98607 9.32456 9.99884 9.03983 9.90835C8.86719 9.85357 8.6458 9.78044 8.36221 9.65797C7.2479 9.17681 6.49485 8.0968 6.35253 7.89268C6.34256 7.87838 6.33559 7.86838 6.3317 7.86318L6.33074 7.86191C6.26783 7.77798 5.84635 7.21561 5.84635 6.6336C5.84635 6.08608 6.1153 5.79911 6.23909 5.66701C6.24757 5.65796 6.25537 5.64964 6.26236 5.64201C6.37131 5.52301 6.50009 5.49326 6.57933 5.49326C6.65856 5.49326 6.73788 5.49399 6.80714 5.49747C6.81568 5.4979 6.82457 5.49784 6.83376 5.49779C6.90303 5.49738 6.98938 5.49688 7.07457 5.70151C7.10734 5.78026 7.1553 5.89701 7.20588 6.02014C7.30816 6.26914 7.42116 6.54426 7.44104 6.58407C7.47077 6.64357 7.49057 6.71296 7.45095 6.79232C7.44501 6.80422 7.43951 6.81545 7.43425 6.82618C7.40449 6.88693 7.3826 6.93162 7.3321 6.9906C7.31224 7.01378 7.29171 7.03878 7.27118 7.06378C7.23029 7.11358 7.1894 7.16337 7.1538 7.19885C7.09429 7.25812 7.03235 7.32242 7.10168 7.44142C7.17102 7.56042 7.40957 7.94964 7.76293 8.26483C8.14277 8.60364 8.4729 8.74683 8.64023 8.81942C8.67291 8.83359 8.69938 8.84507 8.7188 8.85479C8.83762 8.91431 8.90696 8.90435 8.9763 8.82505C9.04564 8.74572 9.27343 8.47796 9.35266 8.35898C9.43189 8.24003 9.51116 8.25984 9.62009 8.29949C9.72907 8.33919 10.3134 8.62671 10.4323 8.6862C10.4555 8.69782 10.4772 8.70831 10.4973 8.71802Z"
                fill=${this.iconColor}
              />
            </svg>
          `;
          break;
        case "Instagram":
          linkContext = `
            <svg
              width="23"
              height="23"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM6.24109 3.75911C6.69621 3.7384 6.84163 3.73333 8.0004 3.73333H7.99907C9.15819 3.73333 9.30308 3.7384 9.7582 3.75911C10.2124 3.77991 10.5226 3.85182 10.7947 3.95734C11.0755 4.06623 11.3129 4.21201 11.5502 4.44934C11.7876 4.6865 11.9333 4.92455 12.0427 5.20517C12.1476 5.47646 12.2196 5.78651 12.2409 6.24074C12.2613 6.69585 12.2667 6.84128 12.2667 8.00004C12.2667 9.15881 12.2613 9.30388 12.2409 9.759C12.2196 10.213 12.1476 10.5232 12.0427 10.7946C11.9333 11.0751 11.7876 11.3131 11.5502 11.5503C11.3131 11.7876 11.0755 11.9338 10.7949 12.0428C10.5234 12.1483 10.213 12.2202 9.75882 12.241C9.3037 12.2617 9.15872 12.2668 7.99987 12.2668C6.84119 12.2668 6.69585 12.2617 6.24074 12.241C5.7866 12.2202 5.47646 12.1483 5.20499 12.0428C4.92455 11.9338 4.6865 11.7876 4.44943 11.5503C4.21218 11.3131 4.0664 11.0751 3.95734 10.7945C3.85191 10.5232 3.78 10.2131 3.75911 9.75891C3.73849 9.30379 3.73333 9.15881 3.73333 8.00004C3.73333 6.84128 3.73867 6.69576 3.75902 6.24065C3.77947 5.7866 3.85147 5.47646 3.95725 5.20508C4.06658 4.92455 4.21236 4.6865 4.4497 4.44934C4.68685 4.21209 4.9249 4.06631 5.20553 3.95734C5.47682 3.85182 5.78687 3.77991 6.24109 3.75911Z"
                fill=${this.iconColor}
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.6175 4.50204C7.69182 4.50193 7.77177 4.50196 7.85805 4.502L8.00026 4.50204C9.13947 4.50204 9.27449 4.50613 9.72436 4.52658C10.1404 4.5456 10.3661 4.61511 10.5165 4.67351C10.7157 4.75085 10.8576 4.84329 11.0069 4.99263C11.1562 5.14196 11.2486 5.28418 11.3262 5.4833C11.3846 5.63352 11.4542 5.8593 11.4731 6.27531C11.4935 6.72509 11.498 6.8602 11.498 7.99888C11.498 9.13756 11.4935 9.27267 11.4731 9.72245C11.4541 10.1385 11.3846 10.3642 11.3262 10.5145C11.2488 10.7136 11.1562 10.8554 11.0069 11.0046C10.8575 11.1539 10.7158 11.2464 10.5165 11.3237C10.3663 11.3824 10.1404 11.4517 9.72436 11.4707C9.27458 11.4912 9.13947 11.4956 8.00026 11.4956C6.86095 11.4956 6.72593 11.4912 6.27615 11.4707C5.86014 11.4515 5.63436 11.382 5.48387 11.3236C5.28476 11.2463 5.14254 11.1538 4.9932 11.0045C4.84387 10.8552 4.75142 10.7133 4.67391 10.5141C4.61551 10.3639 4.54591 10.1381 4.52698 9.7221C4.50653 9.27232 4.50244 9.1372 4.50244 7.99781C4.50244 6.85842 4.50653 6.72402 4.52698 6.27424C4.546 5.85824 4.61551 5.63245 4.67391 5.48205C4.75124 5.28294 4.84387 5.14072 4.9932 4.99138C5.14254 4.84205 5.28476 4.7496 5.48387 4.67209C5.63428 4.61342 5.86014 4.54409 6.27615 4.52498C6.66975 4.5072 6.82229 4.50187 7.6175 4.50098V4.50204ZM10.2778 5.2105C9.99512 5.2105 9.76579 5.43956 9.76579 5.72232C9.76579 6.00499 9.99512 6.23433 10.2778 6.23433C10.5605 6.23433 10.7898 6.00499 10.7898 5.72232C10.7898 5.43965 10.5605 5.21032 10.2778 5.21032V5.2105ZM5.80912 7.99986C5.80912 6.78983 6.79015 5.80877 8.00017 5.80872C9.21022 5.80872 10.191 6.7898 10.191 7.99986C10.191 9.20991 9.21031 10.1905 8.00026 10.1905C6.7902 10.1905 5.80912 9.20991 5.80912 7.99986Z"
                fill=${this.iconColor}
              />
              <path
                d="M8.00036 6.57764C8.78579 6.57764 9.4226 7.21435 9.4226 7.99987C9.4226 8.7853 8.78579 9.42211 8.00036 9.42211C7.21484 9.42211 6.57812 8.7853 6.57812 7.99987C6.57812 7.21435 7.21484 6.57764 8.00036 6.57764Z"
                fill=${this.iconColor}
              />
            </svg>
          `;
          break;
      }
      return linkContext;
    },
  },
};
</script>
<style></style>
