import App from './App.vue'
import Bottom from './Bottom.vue'
import HeaderBottom from './HeaderBottom.vue'

window.FPI.extension.register('#ext-announcement-bar-top-view', {
  mounted(element) {
      // mount the extension / Vue app on the given ID
      window.FPI.extension.mountApp({
          element,
          component: App,
      })
  },
})

window.FPI.extension.register('#ext-announcement-bar-bottom-view', {
  mounted(element) {
      // mount the extension / Vue app on the given ID
      window.FPI.extension.mountApp({
          element,
          component: Bottom,
      })
  },
})

window.FPI.extension.register('#ext-announcement-bar-header-bottom-view', {
  mounted(element) {
      // mount the extension / Vue app on the given ID
      window.FPI.extension.mountApp({
          element,
          component: HeaderBottom,
      })
  },
})
