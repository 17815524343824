let user = null;
export const getUser = () => {
  if (user) {
    return user;
  }
  if (!window.FPI) {
    return;
  }
  window.FPI.state.user.subscribe((data) => {
    user = data.user;
  });
  return user;
};
