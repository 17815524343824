<template>
  <div
    :id="[generateDynamicID]"
    :class="[
      { 'ab-sticky-top': isStickyTop, 'ab-sticky-bottom': isStickyBottom },
    ]"
    ref="rotatingBarRef"
  >
    <div
      v-if="!isHidden && showBar"
      class="ab-rotating-bar"
    >
      <SwipeSlider
        :value="value"
        class="ab-swipe-slider-ref"
        v-if="value?.configs?.displayConfig?.transitionStyle == 'Slide'"
      />
      <PullSlider
        :value="value"
        class="ab-pull-slider-ref"
        v-if="value?.configs?.displayConfig?.transitionStyle == 'Push'"
      />
      <FadeSlider
        :value="value"
        class="ab-fade-slider-ref"
        @heightChanged="updateContainerHeight"
        v-if="value?.configs?.displayConfig?.transitionStyle == 'Fade'"
      />

      <div
        class="ab-flex-item ab-close-icon"
        v-if="value.configs.displayConfig.showCloseIcon"
        @click="hideBar()"
      >
        <svg
          width="8"
          height="8"
          viewBox="0 0 9 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            :style="{ fill: value?.configs?.displayConfig?.iconColor }"
            d="M8.32311 0.874286L7.48484 0L4.16152 3.46614L0.838201 0L-6.10352e-05 0.874286L3.32326 4.34043L-6.10352e-05 7.80657L0.838201 8.68085L4.16152 5.21471L7.48484 8.68085L8.32311 7.80657L4.99978 4.34043L8.32311 0.874286Z"
            fill="#8e2e2e"
          ></path>
        </svg>
      </div>
    </div>
  </div>
</template>
<script>
import SwipeSlider from "./../sliders/swipe-slider";
import PullSlider from "./../sliders/pull-slider";
import FadeSlider from "./../sliders/fade-slider";

import { Stuck } from "stuck-js";

export default {
  name: "RotatingBar",
  components: {
    SwipeSlider,
    PullSlider,
    FadeSlider,
  },
  props: {
    value: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
  data() {
    return {
    //   value: {
    //     configs: {
    //       displayConfig: {
    //         barBackground: "Solid",
    //         backgroundColor: "#7b61ff",
    //         barPosition: "Top",
    //         showCloseIcon: true,
    //         iconColor: "#FFFFFF",
    //         stickyBar: false,
    //         pattern: "Pattern 1",
    //         transitionSpeed: "Fast",
    //         transitionStyle: "Fade",
    //       },
    //       delayTimer: {
    //         show: false,
    //         showBar: 3000,
    //         timer: 3,
    //       },
    //       targetPageAndPlatform: {
    //         page: [],
    //         platform: [],
    //       },
    //     },
    //     bars: [
    //       {
    //         type: "Announcement Bar",
    //         index: 0,
    //         configs: {
    //           content: {
    //             text: "Announcement 1 Announcement 1 Announcement 1 Announcement 1"
    //           },
    //           callToAction: {
    //             type: "Button",
    //             category: "link",
    //             show: true,
    //             text: "Learn More",
    //             bg: "#F8F8F8",
    //             fontColor: "#4F4F4F",
    //             link: "",
    //             isWiggle: true
    //           }
    //         },
    //         isChildren: true,
    //         description: "Show announcements and special deals",
    //         _id: "64258608dbbdebced7439de6"
    //       },
    //       {
    //         type: "Free Shipping Bar",
    //         index: 2,
    //         configs: {
    //           freeShipping: {
    //             minCartValue: 1000
    //           },
    //           contentStates: [
    //             {
    //               name: "Start Message",
    //               content: {
    //                 text: "<p>Get Free shipping on oFree shipping on orders aboverders above ₹{{amount}}</p>",
    //                 pattern: "{{amount}}",
    //                 patternErrorMessage: "{{amount}} variable is missing"
    //               }
    //             },
    //             {
    //               name: "Progress Message",
    //               description: "Text for Free Shipping Bar when cart total is less than the minimum cart value",
    //               content: {
    //                 text: "<p>You are ₹{{remaining_amount}} away from free shipping</p>",
    //                 pattern: "{{remaining_amount}}",
    //                 patternErrorMessage: "{{remaining_amount}} variable is missing"
    //               }
    //             },
    //             {
    //               name: "Success Message",
    //               description: "Text for Free Shipping Bar when cart total is at or above minimum value",
    //               content: {
    //                 text: "<p>Yay! Your order will be shipped for Free</p>"
    //               }
    //             }
    //           ]
    //         },
    //         isChildren: true,
    //         description: "Show free shipping offer for updated cart values",
    //         _id: "642bbb34fd30edc43d6f4b1b"
    //       },
    //       {
    //         type: "Countdown Timer Bar",
    //         index: 1,
    //         configs: {
    //           countdownTimer: {
    //             start: 1681280751450,
    //             end: 1682144845773,
    //             style: "Basic",
    //             fontColor: "#5644B3",
    //             bg: "#FFFFFF"
    //           },
    //           content: {
    //             text: "<p>Countdown Timer BarCountdown Timer BarCountdown Timer Bar</p>"
    //           },
    //           callToAction: {
    //             type: "Button",
    //             category: "link",
    //             show: true,
    //             text: "Learn More",
    //             bg: "#F8F8F8",
    //             fontColor: "#4F4F4F",
    //             link: "",
    //             isWiggle: true
    //           }
    //         },
    //         isChildren: true,
    //         description: "Show special deals with countdown timer",
    //         _id: "642bbb34fd30edc43d6f4b1a"
    //       }
    //     ]
    // },
      isHidden: false,
      showBar: true,
      containerHeight: null,
      timeIntervalRef: {
        showBarOnDelayRef: null,
        showBarAfterTimerRef: null,
      },
    };
  },
  mounted() {
    this.showBarOnDelay();

    if (this.isStickyTop) {
      new Stuck(
        [{ selector: `#rotating-bar-ref-${this.index}`, wrapper: "#app" }],
        { marginTop: 0 }
      );
    }
  },
  computed: {
    removeCloseIconOnBars() {
      return !(this.value?.configs?.displayConfig?.showCloseIcon ?? false);
    },
    showCloseIconforFade() {
      return (
        this.value?.configs?.displayConfig?.transitionStyle === "Fade" &&
        this.containerHeight > 0
      );
    },
    isStickyTop() {
      return this.value.configs.displayConfig.stickyBar &&
        this.value.configs.displayConfig.barPosition.toLowerCase() == "top"
        ? true
        : false;
    },
    isStickyBottom() {
      return this.value.configs.displayConfig.stickyBar &&
        this.value.configs.displayConfig.barPosition.toLowerCase() == "bottom"
        ? true
        : false;
    },
    generateDynamicID() {
      return `rotating-bar-ref-${this.index}`;
    },
    // getTheClosestWrapper(){
    //     return this.$refs.rotatingBarRef.closest('#ext-announcement-bar-top-view');
    // },
  },
  methods: {
    /**
     * Method triggered once close button is clicked
     */
    hideBar() {
      this.isHidden = true;
      this.showBarAfterTimer();
    },

    /**
     * Method to show bar after delay
     */
    showBarOnDelay() {
      if (this.value.configs.delayTimer.show === true) {
        if (this.value.configs.delayTimer.showBar > 0) {
          this.showBar = false;
          this.timeIntervalRef.showBarOnDelayRef = setTimeout(() => {
            this.showBar = true;
          }, this.value.configs.delayTimer.showBar);
        } else {
          this.showBar = true;
        }
      }
    },

    /**
     * Method to show bar after it has been closed after certain time interval
     */
    showBarAfterTimer() {
      if (this.value.configs.delayTimer.show === true) {
        this.timeIntervalRef.showBarAfterTimerRef = setTimeout(() => {
          this.isHidden = false;
        }, Number(this.value.configs.delayTimer.timer * 1000));
      }
    },

    updateContainerHeight(height) {
      this.containerHeight = height;
    },
  },

  destroyed() {
    clearTimeout(this.timeIntervalRef.showBarOnDelayRef);
    clearTimeout(this.timeIntervalRef.showBarAfterTimerRef);
  },
};
</script>
<style scoped lang="scss">
.ab-rotating-bar {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 42px;

  .ab-swipe-slider-ref {
    flex: 1 0 50%;
  }
}

.ab-close-icon {
  cursor: pointer;
  position: absolute;
  margin-right: 1rem;
}
.ab-sticky-top {
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;
}
.ab-sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 10;
  width: 100%;
}

.fade-in {
    -webkit-animation: fade-in 1.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0s both;
    animation: fade-in 1.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0s both;
  }
  @-webkit-keyframes fade-in {
    0% {
      display: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
</style>
