<template>
    <RenderBars :bars="docs" v-if="docs" />
</template>

<script>
import axios from "axios";

import RenderBars from "./components/render-bars.vue";

export default {
  name: "App",
  components: {
    RenderBars,
  },
  data: function () {
    return {
      docs: null
    };
  },
  async mounted() {
    await this.getTopBars();
  },
  methods: {
    getTopBars: async function () {
      let url = window.location.href.split("/")[2];
      const response = await axios.get(
        `https://${url}/ext/announcement-bar/application/api/v1/bars?page_no=1&page_size=10&active=true&position=Top`
      );
      this.docs = response.data.data.docs && response.data.data.docs.length? response.data.data.docs: null;
    },
  },
};
</script>

<style scoped>
body {
  margin: 0;
}
</style>
