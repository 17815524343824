const PLATFORMS = {
  IOS: "ios",
  ANDROID: "android",
  WEB: "web",
};

export default function getPlatform() {
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  if (isIOS) {
    return PLATFORMS.IOS;
  }

  const isAndroid = /Android/.test(navigator.userAgent);

  if (isAndroid) {
    return PLATFORMS.ANDROID;
  }

  return PLATFORMS.WEB;
}
