<template>
  <div>
    <div id="header_bottom_sticky_bar_ref" ref="headerBottomBarRef">
      <RenderBars :bars="stickyDocs" v-if="stickyDocs" />
    </div>
    <RenderBars :bars="docs" v-if="docs" />
  </div>
</template>

<script>
import axios from "axios";

import RenderBars from "./components/render-bars.vue";

export default {
  name: "HeaderBottom",
  components: {
    RenderBars,
  },
  data: function () {
    return {
      docs: null,
      headerBottomBarRef: null,
      topPosition: 0,
      stickyDocs: null,
      header: {
        wrapperHeight: null,
        height: null,
        ref: null,
      },
      stickyBars: {
        height: null,
        top: null,
      },
      observerRef: null,
    };
  },
  async mounted() {
    await this.getTopBars();

    /**
     * FIGURING OUT THE HEADER WITH STICKY OR FIXED POSITION
     */
    const headerWrapper = document.querySelector(".ct-header-wrapper");
    this.header.wrapperHeight = headerWrapper.offsetHeight;
    this.header.ref = this.getHeader(headerWrapper);

    this.observerRef = setInterval(() => {
      this.alignBars();
    }, 100);
  },
  methods: {
    getHeader: function (ele) {
      if (["fixed", "sticky"].includes(getComputedStyle(ele)["position"])) {
        return ele;
      } else if (ele.children.length === 1) {
        return this.getHeader(ele.children[0]);
      } else {
        return null;
      }
    },
    alignBars: function () {
      /**
       * CHECKING A HEADER IS STICKY OR FIXED
       * CHECKING FOR STICKY BARS
       */
      if (this.header.ref && this.stickyDocs && this.stickyDocs.length) {
        const headerHeight = this.header.ref.offsetHeight;

        /**
         * GETTING ALL THE DOM OBJECTS
         * FOR POSITIONING
         */

        /**
         * HOLDER OF ALL THE STICKY BARS
         */
        this.headerBottomBarRef = document.querySelector(
          "#header_bottom_sticky_bar_ref"
        );
        /**
         * HOLDER OF ALL THE BARS
         */
        this.bindingRef = document.querySelector(
          "#ext-announcement-bar-header-bottom-view"
        );

        /**
         * TO AVOID COMPUTATION
         * IF STICKY BARS HEIGHT IS CONSTANT
         */
        if (!this.stickyBars.height) {
          /**
           * POSITIONING THE GROUP OF STICKY BARS
           */
          this.headerBottomBarRef.classList.add("ab-header-bottom-fixed");
        }
        if (!this.stickyBars.top || this.stickyBars.top !== headerHeight) {
          this.headerBottomBarRef.style.top = `${headerHeight}px`;
          this.stickyBars.top = headerHeight;
        }
        if (this.headerBottomBarRef.offsetHeight === this.stickyBars.height) {
          return;
        }

        
        this.stickyBars.height = this.headerBottomBarRef.offsetHeight;


        /**
         * marginTop: POSITION THE NON STICKY BARS
         */
        let marginTop = this.stickyBars.height;


        if (!this.header.wrapperHeight) {
          marginTop += headerHeight;
        }

        /**
         * POSITIONING THE GROUP OF NON STICKY BARS
         */
        this.bindingRef.style.marginTop = `${marginTop}px`;
      }
    },
    getTopBars: async function () {
      let url = window.location.href.split("/")[2];
      try {
        const response = await axios.get(
          `https://${url}/ext/announcement-bar/application/api/v1/bars?page_no=1&page_size=10&active=true&position=Top`
        );

        this.docs =
          response.data.data.docs && response.data.data.docs.length
            ? response.data.data.docs.filter(
              (x) => !x.configs.displayConfig.stickyBar
            )
            : null;
        this.stickyDocs =
          response?.data?.data?.docs && response.data.data.docs.length
            ? response.data.data.docs
              .filter((x) => x.configs.displayConfig.stickyBar)
              .map((x) => ({
                ...x,
                configs: {
                  ...x.configs,
                  displayConfig: {
                    ...x.configs.displayConfig,
                    stickyBar: false,
                  },
                },
              }))
            : null;
      } catch (error) {
        console.log("FAILED_TO_FETCH_BARS", error && error.message);
      }
    },
  },
  destroyed() {
    if (this.observerRef) {
      clearInterval(this.observerRef)
    }
  }
};
</script>

<style lang="scss">
@import "./styles/storefront-variables";
@import "./styles/announcementbar.scss";

/// all classes should start with `.ab-`
.ab-header-bottom-fixed {
  position: fixed;
  z-index: 3;
  width: 100%;
  height: auto;
}
</style>
