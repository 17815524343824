const Bars = {
  "Announcement Bar": "announcement-bar",
  "Countdown Timer Bar": "count-down-timer-bar",
  "Free Shipping Bar": "free-shipping-bar",
  "Contact Form Bar": "contact-form-bar",
  "Social Share Bar": "social-share-bar",
  "Rotating Announcement": "rotating-bar",
};

export default Bars;
