<template>
  <div class="ab-pull-slider-rotating" :style="{ height: heightOfBars[currentSlide] + 'px' }">
    <div class="ab-slider">
      <div class="ab-slider-wrapper" :style="{ transform: 'translateY(' + this.sliderPosition() + 'px)' }"
        ref="pullSlide">
        <div class="ab-slide" v-for="(bar, index) in value.bars" :key="index">
          <component :is="map[bar.type]" v-bind="getProps(bar)"></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarsConfig from "../../configs/bars.config";
import getPlatform from "../../utils/user-agent.util";

import AnnouncementBar from "./../announcement-bar/announcement-bar";
import CountDownTimerBar from "./../countdowntimer-bar/countdowntimer-bar";
import ContactFormBar from "./../contactform-bar/contactform-bar";
import FreeShippingBar from "./../freeshipping-bar/freeshipping-bar";
import SocialShareBar from "./../socialshare-bar/socialshare-bar";
import RotatingBar from "./../rotating-bar/rotating-bar";

export default {
  name: "pullSlider",
  components: {
    AnnouncementBar,
    CountDownTimerBar,
    ContactFormBar,
    FreeShippingBar,
    SocialShareBar,
    RotatingBar,
  },
  props: {
    value: {
      type: Object,
    },
    isCloseIconVisible: {
      type: Boolean,
    },
  },
  data() {
    return {
      map: BarsConfig,
      timeIntervalRef: null,
      currentSlide: 0,
      sliderHeight: null,
      maxHeight: 64,
      platform: null,
      timeOutRef: null,
      /**
       * @description height of each child bar in px
       * @type {number[]}
       */
      heightOfBars: [],
    };
  },
  computed: {
    setTransitionSpeed() {
      switch (this.value.configs.displayConfig.transitionSpeed) {
        case "Slow":
          return 10000;
        case "Med":
          return 5000;
        case "Fast":
          return 3000;
        default:
          return null;
      }
    },
  },
  mounted() {
    // @ts-check
    this.platform = getPlatform();
    /**
     * @type {HTMLDivElement}
     */
    this.timeOutRef = setTimeout(() => {
      const sliderWrapperDiv = this.$refs.pullSlide;
      this.heightOfBars = this.value?.bars?.length && this.value?.bars?.map((x, index) => {
        return Number(sliderWrapperDiv?.children[index]?.offsetHeight);
      });
      this.startAutoplay();
    }, 500);
  },
  methods: {
    startAutoplay() {
      this.timeIntervalRef = setInterval(() => {
        this.currentSlide = (this.currentSlide + 1) % this.value?.bars?.length;
        this.sliderPosition();
      }, this.setTransitionSpeed);
    },
    getProps: function (bar) {
      return {
        value: {
          ...bar,
          configs: {
            ...this.value.configs,
            displayConfig: {
              ...this.value.configs.displayConfig,
              showCloseIcon: false,
              stickyBar: false,
            },
            ...bar.configs,
          },
        },
      };
    },
    sliderPosition() {
      // @ts-check
      let translateY = 0;
      for (let i = 0; i < this.currentSlide; i++) {
        translateY += this.heightOfBars[i];
      }
      return -(translateY);
    }
  },
  destroyed() {
    if (this.timeIntervalRef) {
      clearInterval(this.timeIntervalRef);
    }
    if (this.timeOutRef) {
      clearTimeout(this.timeOutRef);
    }
  },
};
</script>

<style lang="scss">

.ab-pull-slider-rotating {
  overflow: hidden;
  width: 100%;
  height: 42px;

  .ab-slider {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .ab-slider-wrapper {
    display: flex;
    flex-direction: column;
    transition: transform 0.5s ease-in-out;
  }

  .ab-slide {
    flex: 1 0 50%;
    background-color: transparent;

    .ab-container {
      width: 100%;
    }
  }

}
</style>
