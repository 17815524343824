<template>
  <div :style="{ height: heightWrapper + 'px'}" class="fadeWrapper">
    <div class="ab-fade-slider-rotating">
      <div class="ab-slider">
        <div class="ab-items">
          <div
            v-for="(bar, index) in value.bars"
            :key="index"
            :class="{ 'ab-item': true, current: currentIndex === index }"
          >
            <div class="ab-slide" ref="fadeSlide">
              <component :is="map[bar.type]" v-bind="getProps(bar)"></component>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import BarsConfig from "../../configs/bars.config";

import AnnouncementBar from "./../announcement-bar/announcement-bar";
import CountDownTimerBar from "./../countdowntimer-bar/countdowntimer-bar";
import ContactFormBar from "./../contactform-bar/contactform-bar";
import FreeShippingBar from "./../freeshipping-bar/freeshipping-bar";
import SocialShareBar from "./../socialshare-bar/socialshare-bar";
import RotatingBar from "./../rotating-bar/rotating-bar";

export default {
  name: "fadeSlider",
  components: {
    AnnouncementBar,
    CountDownTimerBar,
    ContactFormBar,
    FreeShippingBar,
    SocialShareBar,
    RotatingBar,
  },
  props: {
    value: {
      type: Object,
    },
    isCloseIconVisible: {
      type: Boolean,
    },
  },
  data() {
    return {
      map: BarsConfig,
      currentIndex: 0,
      timeIntervalRef: null,
      timeOutRef: null,
      heightWrapper: null
    };
  },
  computed: {
    setTransitionSpeed() {
      switch (this.value.configs.displayConfig.transitionSpeed) {
        case "Slow":
          return 10000;
        case "Med":
          return 5000;
        case "Fast":
          return 3000;
        default:
          return null;
      }
    },
  },
  mounted() {
    this.timeOutRef = setTimeout(() => {
      this.sendHeightToParent();
    }, 500);
    this.timeIntervalRef = setInterval(() => {
      this.currentIndex = (this.currentIndex + 1) % this.value.bars.length;
      this.sendHeightToParent();
    }, this.setTransitionSpeed);
  },
  methods: {
    sendHeightToParent() {
      this.$nextTick(() => {
        let height = this.$refs.fadeSlide[this.currentIndex].clientHeight;
        this.heightWrapper = height;
        this.$emit("heightChanged", height);
      });
    },
    getProps: function (bar) {
      return {
        value: {
          ...bar,
          configs: {
            ...this.value.configs,
            displayConfig: {
              ...this.value.configs.displayConfig,
              showCloseIcon: false,
              stickyBar: false,
            },
            ...bar.configs,
          },
        },
      };
    },
  },
  destroyed() {
    if (this.timeIntervalRef) {
      window.clearInterval(this.timeIntervalRef);
    }
    if (this.timeOutRef) {
      clearTimeout(this.timeOutRef);
    }
  },
};
</script>

<style scoped lang="scss">

.fadeWrapper{
  height: 42px;
}

.ab-fade-slider-rotating {
  .ab-slider {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: hidden;
  }

  .ab-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.6s ease-in-out;
  }

  .ab-item.current {
    opacity: 1;
  }

  .ab-slide {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: auto;
  }
}


</style>
